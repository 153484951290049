<template>
    <v-container class="fill-height px-0 pt-0" fluid text-xs-center>
        <v-row justify="start">
            <v-col cols="12">
                <v-card class="d-block fill-height fluid pa-0 ma-0" flat tile>
                    <v-scroll-x-transition appear>
                        <v-img :height="$vuetify.breakpoint.xs ? 200 : 250"
                               :max-height="$vuetify.breakpoint.xs ? 200 : 250"
                               :position="$vuetify.breakpoint.xs ? 'right' : 'center'"
                               src="@/assets/images/breadcrumbs-notes.jpg"
                               transition="slide-x-transition"
                               width="100%">
                            <v-container
                                class="fill-height align-end bottom-gradient"
                                fluid>
                                <v-row align="center" justify="center">
                                    <v-col>
                                        <div class="white--text pb-10">
                                            <span class="pl-4 pl-md-8 text-h5 text-md-h4 font-weight-regular">
                                                Déjenos sus Comentarios
                                            </span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-img>
                    </v-scroll-x-transition>
                </v-card>
            </v-col>
            <v-col class="align-center justify-center" cols="12">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-card class="ma-3" elevation="3">
                            <v-form ref="form" v-model="valid" action="/admin/wireframe" lazy-validation>
                                <v-card-title class="text-h6 white--text blue darken-1">
                                    <span class="headline">Caja de Comentarios</span>
                                </v-card-title>
                                <v-container class="mt-1 px-5">
                                    <v-row class="mt-1">
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="commentItem.name"
                                                :rules="textRules"
                                                dense
                                                label="Nombres y Apellido"
                                                required>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="commentItem.email"
                                                :rules="emailRules"
                                                dense
                                                hint="(Su correo electrónico no será publicado)"
                                                label="Correo electrónico"
                                                persistent-hint
                                                required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-textarea
                                                v-model="commentItem.comment"
                                                :rules="textRules"
                                                counter
                                                dense
                                                label="Comentario"
                                                no-resize
                                                required
                                                rows="6">
                                            </v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-card-actions class="mt-0 pb-5 px-5">
                                    <v-btn :disabled="!valid" color="primary" @click="saveComment">
                                        Enviar Comentario
                                    </v-btn>
                                </v-card-actions>
                            </v-form>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-card class="ma-3" elevation="3">
                            <v-card-title class="white--text blue darken-1">
                                <span>Comentarios recientes</span>
                            </v-card-title>
                            <v-virtual-scroll
                                :item-height="70"
                                :items="comments"
                                height="440">
                                <template v-slot:default="{ index, item }">
                                    <v-list-item :key="index">
                                        <v-list-item-avatar>
                                            <v-avatar
                                                :color="getColor()"
                                                class="white--text"
                                                size="56">
                                                {{ item.name | initials }}
                                            </v-avatar>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title v-html="item.name"></v-list-item-title>
                                            <v-list-item-subtitle v-html="item.comment"></v-list-item-subtitle>
                                            <!--<v-card-text v-html="item.comment"></v-card-text>-->
                                            <v-list-item-subtitle class="mt-2 grey--text">
                                                <span><small>Posteado el {{
                                                        item.created_at | formatDate
                                                    }}</small></span>
                                            </v-list-item-subtitle>
                                            <v-divider v-if="index < comments.length - 1" :key="index"></v-divider>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-btn depressed
                                                   icon
                                                   plain
                                                   @click="showComment(item.comment)">
                                                <v-icon color="orange darken-4"
                                                        small>
                                                    mdi-open-in-new
                                                </v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                </template>
                            </v-virtual-scroll>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-snackbar
            v-model="snackBar"
            :timeout="2000">
            {{ snackText }}
            <template v-slot:action="{ attrs }">
                <v-btn  v-bind="attrs"
                        icon
                        @click="snackBar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-bottom-sheet
            v-model="sheet"
            inset>
            <v-sheet>
                <v-card class="container--fluid text-center" flat width="100vw">
                    <v-btn
                        class="mt-5"
                        color="error"
                        text
                        @click="sheet = false">
                        CERRAR
                    </v-btn>
                </v-card>
                <div class="mx-10 pb-10">
                    <span class="text-caption">
                        Comentario:
                    </span>
                    <v-divider/>
                    <span class="pt-5 text-body-2">
                        {{ sheetText }}
                    </span>
                </div>
            </v-sheet>
        </v-bottom-sheet>
    </v-container>
</template>

<script>
import commentsService from '@/providers/CommentsService';
// import moment from 'moment';

const padDate = function (value) {
    return value < 10 ? '0' + value : value
};

export default {
    name: "CommentsComponent",
    data: () => ({
        sheet: false,
        sheetText: '',
        snackBar: false,
        snackText: '',
        focus: false,
        valid: true,
        comments: [],
        commentItem: {
            name: '',
            email: '',
            comment: '',
            created_at: null
        },
        defaultCommentItem: {
            name: '',
            email: '',
            comment: '',
            created_at: null
        },
        textRules: [
            v => !!v || 'Los datos de este campo son obligatorio!'
        ],
        emailRules: [
            v => !!v || "El Correo Electrónico es obligatorio",
            v => /.+@.+\..+/.test(v) || "El Correo Electrónico debe ser válido"
        ]
    }),
    filters: {
        formatDate(value) {
            /*if (value) {
                return moment(String(value)).format('DD/MM/YYYY `at` hh:mm');
            }*/
            if (value) {
                const date = new Date(value)
                const year = date.getFullYear()
                const month = padDate(date.getMonth() + 1)
                const day = padDate(date.getDate())
                const hours = padDate(date.getHours())
                const minutes = padDate(date.getMinutes())
                // const seconds = padDate(date.getSeconds())
                return day + '-' + month + '-' + year + ' a las ' + ' ' + hours + ':' + minutes;
            }
        },
        initials(value) {
            const name = value.split(' ');
            return `${name[0].charAt(0).toUpperCase()}${name[1] ? name[1].charAt(0).toUpperCase() : ''}`;
        }
    },
    methods: {
        getRandomColor() {
            var letters = '0123456789ABCDEF'.split('');
            var color = '#';
            for (var i = 0; i < 6; i++) {
                color += letters[Math.round(Math.random() * 15)];
            }
            return color;
        },
        genRandomIndex(length) {
            return Math.ceil(Math.random() * (length - 1))
        },
        getColor() {
            let colors = [
                "#1abc9c",
                "#2ecc71",
                "#3498db",
                "#9b59b6",
                "#34495e",
                "#16a085",
                "#27ae60",
                "#2980b9",
                "#8e44ad",
                "#2c3e50",
                "#f1c40f",
                "#e67e22",
                "#e74c3c",
                "#95a5a6",
                "#f39c12",
                "#d35400",
                "#c0392b",
                "#7f8c8d",
            ];
            const colorsLength = colors.length;
            let colorsel = this.genRandomIndex(colorsLength);
            return colors[colorsel];
        },
        showComment(value) {
            this.sheetText = value;
            this.sheet = true;
        },
        getComments() {
            commentsService.getAllComments().then(record => {
                this.comments = record.value;
                // console.log("Lista de Comentarios: ", this.comments);
            });
        },
        saveComment() {
            this.$refs.form.validate();
            if (this.$refs.form.validate(true)) {
                this.comments.push(this.commentItem);
                // Agrega el registro por el metodo POST
                commentsService.addRecord(this.commentItem).then(result => {
                    if (result.success) {
                        this.commentItem = Object.assign({}, this.defaultCommentItem);
                        this.snackText = 'Comentario guardado con éxito...';
                    } else {
                        this.snackText = 'Un error impidió guardar el comentario solicitado!';
                    }
                    this.snackBar = true;
                    this.getComments();
                    this.$refs.form.reset();
                });
            }
        },
    },
    mounted() {
        this.comments = [];
        this.getComments();
    },
}
</script>

<style scoped>
    .bottom-gradient {
        background-image: linear-gradient(
            to bottom,
            rgba(200, 243, 248, 0.29) 0%,
            rgba(51, 54, 57, 0.7) 100%
        );
    }

    #my-lax .v-parallax__content {
        background: linear-gradient(45deg, black, transparent);
    }

    .v-btn {
        text-transform: none !important;
    }

    .blend-opacity {
        opacity: 0.1;
        transition: opacity 0.4s ease-in-out;
        background-color: black;
        background-blend-mode: normal;
    }
</style>